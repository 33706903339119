'use strict';

var cart = require('../cart/cart');
const util = require('./util');

var updateMiniCart = true;
var scrollHeader;
const PAYPAL_BUTTON = 35;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }

    if ($('.move').attr('data-loggedUser') === 'true') {
        $('.add-to-wishlist-messages')
            .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');
    } else {
        $('.add-to-wishlist-messages')
            .append('<div class="add-to-wishlist-alert text-center ' + status + '"><a href="' + data.wishlistUrl + '">' + data.notLoggedMsg + '</a></div>');
    }

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

/**
 * adds top distance to minicart
 */
let topDistance = function () {
    let headeHeight = $('header').height();
    let headerBannerHeight = $('.header-banner').height();
    let $miniCartPopOver = $('.minicart .popover');
    let positionTop = headerBannerHeight - $(window).scrollTop();

    if (positionTop >= 0) {
        $miniCartPopOver.css('top', positionTop + headeHeight);
        scrollHeader = headeHeight + headerBannerHeight;
    } else {
        $miniCartPopOver.css('top', headeHeight);
        scrollHeader = headeHeight;
    }
};

module.exports = function () {
    var timeOut;
    var prevFocusButton;
    cart();

    var closeObj = {
        show: false,
        timeOut: 200
    };

    $(window).on('scroll', topDistance);

    $(window).on('scroll', function () {
        $('.minicart').trigger('cart:show', closeObj);
    });

    $('.minicart').on('cart:showMobile', function (event, response) {
        var messageType = response.error ? 'alert-danger' : 'alert-success';

        if ($('.add-to-cart-messages-pdp').length === 0) {
            $('.primary-images').append(
                '<div class="add-to-cart-messages-pdp"></div>'
            );
        }

        $('.add-to-cart-messages-pdp').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    });

    $('.minicart').on('cart:show', function (event, obj) {
        let $miniCartPopOver = $('.minicart .popover');
        let $modalBackground = $('.modal-background');

        if (obj.show) {
            if ($('.search:visible').length === 0) {
                return;
            }

            if (obj.button) {
                if (obj.button.hasClass('add-to-cart-global')) {
                    prevFocusButton = $('[data-quickview-uid="' + obj.button.data('pid') + '"]');
                } else {
                    prevFocusButton = obj.button;
                }

                $('.minicart a').first().focus();
            }

            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                let headeHeight = $('header').height();
                let headerBannerHeight = $('.header-banner').height();

                if (scrollHeader) {
                    $miniCartPopOver.css('height', window.innerHeight - scrollHeader);
                } else if (headerBannerHeight) {
                    $miniCartPopOver.css('height', window.innerHeight - (headerBannerHeight + headeHeight));
                } else {
                    $miniCartPopOver.css('height', window.innerHeight - headeHeight);
                }

                topDistance();

                if (!updateMiniCart) {
                    $miniCartPopOver.addClass('show');
                    $modalBackground.show();
                    return;
                }

                $miniCartPopOver.addClass('show');
                $modalBackground.show();
                $miniCartPopOver.spinner().start();
                $.get(url, function (data) {
                    $miniCartPopOver.empty();
                    $miniCartPopOver.append(data);
                    updateMiniCart = false;
                    $.spinner().stop();

                    setTimeout(function () {
                        $('.minicart .product-summary').css('max-height', $('.minicart .cart').height() - ($('.minicart-header').height() + $('.minicart-footer').height() + PAYPAL_BUTTON));
                    }, 200);
                });
            }
        } else {
            timeOut = setTimeout(function () {
                $modalBackground.hide();
                $miniCartPopOver.removeClass('show');
                $miniCartPopOver.css('height', '');
                if (prevFocusButton) {
                    prevFocusButton.focus();
                }

                prevFocusButton = false;
            }, obj.timeOut);
        }

        $('.remove-btn').on('click', setTimeout(function () {
            $('body > .modal-backdrop').remove();
        }, 300));
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal).removeClass('d-none');

            $('.minicart .minicart-link').attr({
                'aria-label': count.cart.resources.minicartCountOfItems,
                title: count.cart.resources.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin', function () {
        var obj = {
            show: true
        };
        $('.minicart').trigger('cart:show', obj);
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('.minicart').on('mouseleave focusout', function (event) {
        if (event.type === 'focusout' && $(event.target).hasClass('js-view-cart-link')) {
            $('.minicart').trigger('cart:show', closeObj);
            return;
        }

        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart').trigger('cart:show', closeObj);
    });

    $('body').on('focusin', '.wishlist', function () {
        if ($('.minicart .popover').length) {
            if ($('.minicart .popover').hasClass('show')) {
                $('.minicart').trigger('cart:show', closeObj);
            }
        }
    });

    $('body').on('mouseenter focusin touchstart', '.minicart .popover', function () {
        clearTimeout(timeOut);
    });

    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });

    $('body').on('click', '.product-move .js-add-to-wishlist-cart', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var button = $(this);
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);

                button.find('i').removeClass('icon-heart-small').addClass('icon-heart-small-hover');
                button.removeClass('js-add-to-wishlist-cart').addClass('js-remove-from-wishlist-cart');
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });

    $('body').on('click', '.product-move .js-remove-from-wishlist-cart', function (e) {
        e.preventDefault();
        var url = $(this).data('removeurl');
        var pid = $(this).data('pid');
        var button = $(this);

        var newUrl = util.getPathFromUrl(url);

        $.spinner().start();
        $.ajax({
            url: `${newUrl}?pid=${pid}`,
            type: 'get',
            dataType: 'json',
            data: {},
            success: function (data) {
                displayMessageAndRemoveFromCart(data);

                button.find('i').removeClass('icon-heart-small-hover').addClass('icon-heart-small');
                button.removeClass('js-remove-from-wishlist-cart').addClass('js-add-to-wishlist-cart');
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
};
