var util = require('app_iro/components/util');

/**
 * Create redirect pop in
 */
function redirectPopIn() {
    var didomiLocalStorage = window.localStorage.getItem('euconsent-v2');

    if (!didomiLocalStorage) {
        return;
    }

    if ($('.geolocateRedirect').length) {
        $.ajax({
            url: $('.geolocateRedirect').data('url'),
            type: 'GET',
            dataType: 'JSON'
        }).always(function (data) {
            if (data.statusText === 'error') return;

            $('body').prepend(data.responseText);
            var $popin = $('#redirectpopin');
            var $switchBtn = $('.btn.switch');

            if ($popin.data('redirect')) {
                $popin.modal('show');

                util.focusModal($popin);

                $switchBtn.on('click', function (e) {
                    e.preventDefault();
                    var action = $(this).data('action');
                    var localeCode = $(this).data('locale');
                    var localeCurrencyCode = $(this).data('currencycode');
                    var queryString = $(this).data('params');
                    var url = $(this).data('set-locale-url');

                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'json',
                        data: {
                            code: localeCode,
                            queryString: queryString,
                            CurrencyCode: localeCurrencyCode,
                            action: action
                        },
                        success: function (response) {
                            $.spinner().stop();
                            if (response && response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                });

                $('.stay').on('click', function () {
                    $popin.modal('hide');
                    var date = new Date();
                    date.setTime(date + (60 * 60 * 1000));
                    document.cookie = 'StayOnCurrentSite=true; expires=' + date.toGMTString() + '; path=/';
                    window.localStorage.setItem('redirectPopInClosed', 'true');
                });

                $('.switch').on('click', function () {
                    window.localStorage.setItem('redirectPopInClosed', 'true');
                });
            }
        });
    }
}

$(document).ready(function () {
    window.didomiEventListeners = window.didomiEventListeners || [];

    window.didomiEventListeners.push({
        event: 'notice.hidden',
        listener: redirectPopIn
    });

    redirectPopIn();

    $(document).on('show.bs.modal', '#redirectpopin', function () {
        window.localStorage.setItem('redirectPopInClosed', 'false');
    });
});
