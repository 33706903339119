require('bootstrap-show-password/dist/bootstrap-show-password');

$(document).ready(function () {
    if ($('.custom-password').length > 0) {
        if ($('.custom-password').find('input.is-invalid')) {
            $('input.is-invalid').closest('.input-group').addClass('is-invalid');
        } else {
            let $inputGroup = $('.custom-password').find('.input-group');

            if ($inputGroup.hasClass('is-invalid')) {
                $inputGroup.removeClass('is-invalid');
            }
        }
    }
});
