'use strict';


module.exports = {
    /**
     * remove all parameters from url
     * @param {string} url - Url for remove from wishlist
     * @return {string} - New url
     */
    getPathFromUrl(url) {
        return url.split('?')[0];
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    interestedIn: function (form) {
        var $interestedInWomen = $(form).find('[id^=interested-in-women]');
        var $interestedInMen = $(form).find('[id^=interested-in-men]');
        var $interestedIn = $(form).find('input[name^=interestedIn]:checked').val();
        var $error = $(form).find('.js-error-interested');

        if (!$interestedIn) {
            $interestedInWomen.parent().addClass('is-invalid');
            $interestedInMen.parent().addClass('is-invalid');
            $interestedInWomen.addClass('is-invalid');
            $interestedInMen.addClass('is-invalid');
            $error.css('display', 'block');
        } else {
            $interestedInWomen.parent().removeClass('is-invalid');
            $interestedInMen.parent().removeClass('is-invalid');
            $interestedInWomen.removeClass('is-invalid');
            $interestedInMen.removeClass('is-invalid');
            $error.css('display', 'none');
        }
    },

    checkValue: function (str, max) {
        var value = str;
        if (str.charAt(0) !== '0' || str === '00') {
            var num = parseInt(str, 10);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            value = num > parseInt(max.toString().charAt(0), 10)
                 && num.toString().length === 1 ? '0' + num : num.toString();
        }
        return value;
    },

    focusModal: function (modal) {
        const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        if (modal.length) {
            const focusableContent = modal.find(focusableElements);
            const firstFocusableElement = focusableContent.first();
            const lastFocusableElement = focusableContent.last();

            $(document).on('keydown', function (e) {
                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey && document.activeElement === firstFocusableElement[0]) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                } else if (document.activeElement === lastFocusableElement[0]) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            });

            firstFocusableElement.focus();
        }
    },

    removeMenuDropDown: function (menuItem) {
        const childrenItem = menuItem.children();

        if (childrenItem.next().hasClass('showDropdown')) {
            childrenItem.css('font-family', 'NeueHaasDisplay');
            childrenItem.next().removeClass('showDropdown');
            childrenItem.next().find('.dropdown-menu').removeClass('showDropdown');
            childrenItem.attr('aria-expanded', 'false');
        }
    }
};
